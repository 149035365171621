import { useState, useEffect } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import {
  InitCategoryItems,
  GetCategoryItems,
} from '../../Pages/SearchPage/Search';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import ProductCard from '../../Organisms/ProductCard/ProductCard';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import ProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import Grid from '../../Atoms/Grids/Grid';
import LoadMoreContainer from '../../Molecules/LoadMoreContainer/LoadMoreContainer';
import { styled } from '../../stitches.config';
import { GridWidth } from '../../Enums/GridSize.enum';
import GridListToggle from '../../Molecules/ButtonGroups/GridListToggle';
import SearchSorter from '../../Search/Models/SearchSorter.interface';
import FilterBar from '../../Molecules/FilterBar/FilterBar';
import ProductSorter from '../../Molecules/ProductSorter/ProductSorter';
import CategoryBar from '../../Molecules/CategoryBar/CategoryBar';
import KexFacetGroupFilter from '../../Search/Models/KexFacetGroupFilter.interface';
import GridItem from '../../Atoms/Grids/GridItem';
import ContentAreaItem from '../../Shared/Models/ContentAreaItem.interface';
import IIProductListingBlockModel from './Models/ProductListingBlockModel.interface';

interface ProductListingBlockModel extends ContentAreaItem {
  content: IIProductListingBlockModel;
  blockId: any;
}

function ProductListingBlock({
  content: { categoryId, itemsPerLoad, defaultSearchQuery, showFilter },
  blockId,
}: ProductListingBlockModel) {
  const { languageRoute } = useAppSettingsData();
  const {
    'categoryPage/productCounter': productCounterLabel,
    'categoryPage/loadMore': loadMoreText,
    'categoryPage/noFilterResult': noFilterResultLabel,
  } = useTranslationData();

  const { pageId, channelId } = useCurrentPage<PageModelBase>();

  const requestContext = JSON.stringify({
    currentPageSystemId: pageId,
    categorySystemId: categoryId,
    channelSystemId: channelId,
  });

  const dispatchLoading = useKexLoadingCircle();
  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [listView, setListView] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState<SearchSorter>();
  const [sorters, setSorters] = useState<SearchSorter[]>();
  const [filter, setFilter] = useState<string>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinHuge);
  const [facetFilters, setFacetFilters] = useState<KexFacetGroupFilter[]>();
  const [priceValue, setPriceValue] = useState<number[]>();

  useEffect(() => {
    initSearch();
  }, [categoryId]);

  const initSearch = () => {
    dispatchLoading('add');
    const filterCriteria = defaultSearchQuery?.split('\n') ?? [];
    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substr(1) || '')
      .split('&')
      .filter((param) => {
        const [id, value] = param.split('=');
        return param.length > 0 && !ignoredParams.includes(id);
      });

    const querystring = [...unChangedParams, ...filterCriteria].join('&');
    InitCategoryItems(
      categoryId,
      itemsPerLoad,
      requestContext,
      languageRoute,
      querystring
    ).then((data: FullSearchResult) => {
      if (
        data &&
        data?.productSearchResult &&
        data?.productSearchResult?.numberOfItems > 0
      ) {
        setSearchResult(data);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(
            (x: SearchSorter) => x.selected
          )
        );
        setSorters(data?.productSearchResult?.sorters);
      } else {
        setSearchResult(data);
      }
      dispatchLoading('remove');
    });
  };

  const loaded = searchResult
    ? (searchResult?.productSearchResult?.items.length /
        searchResult?.productSearchResult?.availableItems) *
      100
    : 0;

  const counterText =
    searchResult && searchResult?.productSearchResult && productCounterLabel
      ? productCounterLabel
          .replace(
            '{0}',
            searchResult?.productSearchResult?.items.length.toString()
          )
          .replace(
            '{1}',
            searchResult?.productSearchResult?.availableItems.toString()
          )
      : '';

  const disabled =
    searchResult &&
    searchResult?.productSearchResult?.items.length ===
      searchResult?.productSearchResult?.availableItems;

  const loadedItems = searchResult?.productSearchResult?.items?.length ?? 0;
  const take = itemsPerLoad;

  const fetchMoreItems = () => {
    if (searchResult) {
      dispatchLoading('add');
      const filterCriteria = defaultSearchQuery?.split('\n') ?? [];
      const ignoredParams = ['page'];
      const unChangedParams = (window.location.search.substr(1) || '')
        .split('&')
        .filter((param) => {
          const [id, value] = param.split('=');
          return param.length > 0 && !ignoredParams.includes(id);
        });

      const querystring = [...unChangedParams, ...filterCriteria].join('&');

      GetCategoryItems(
        categoryId,
        take,
        requestContext,
        languageRoute,
        searchResult,
        loadedItems,
        selectedSorter?.sortBy,
        selectedSorter?.sortDirection,
        querystring
      ).then((data) => {
        if (data) {
          setSearchResult(data);
          dispatchLoading('remove');
        }
      });
    }
  };

  const onSorterChange = (
    sortBy: string,
    sortDirection: number,
    closeSorterDropdown: () => void
  ) => {
    const filterCriteria = defaultSearchQuery?.split('\n') ?? [];
    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substr(1) || '')
      .split('&')
      .filter((param) => {
        const [id, value] = param.split('=');
        return param.length > 0 && !ignoredParams.includes(id);
      });

    const querystring = [...unChangedParams, ...filterCriteria].join('&');

    closeSorterDropdown();
    if (searchResult !== undefined) {
      dispatchLoading('add');
      GetCategoryItems(
        categoryId,
        take,
        requestContext,
        languageRoute,
        undefined,
        undefined,
        sortBy,
        sortDirection,
        querystring
      ).then((data) => {
        setSearchResult(data);
        setSorters(data.productSearchResult?.sorters);
        setSelectedSorter(
          data?.productSearchResult?.sorters.find(
            (sorter: SearchSorter) => sorter.selected
          )
        );
        dispatchLoading('remove');
      });
    }
  };

  const clearFilter = () => {
    setFilter(undefined);
    setFacetFilters(undefined);

    const url = window.location.href.replace(window.location.search, '');
    window.history.pushState('search', 'Search Page', url);

    initSearch();
  };

  const onFilterChange = (queryString: string) => {
    dispatchLoading('add');
    GetCategoryItems(
      categoryId,
      take,
      requestContext,
      languageRoute,
      undefined,
      undefined,
      selectedSorter?.sortBy,
      selectedSorter?.sortDirection,
      queryString
    ).then((data) => {
      setSearchResult(data);
      setSorters(data?.productSearchResult?.sorters);
      setSelectedSorter(
        data?.productSearchResult?.sorters.find(
          (sorter: SearchSorter) => sorter.selected
        )
      );
      setFilter(queryString);

      const url =
        window.location.href.replace(window.location.search, '') +
        `${queryString ? '?' : ''}${queryString}`;
      window.history.pushState('search', 'Search Page', url);

      dispatchLoading('remove');
    });
  };

  return (
    <GridItem>
      <ProductContainer data-litium-block-id={blockId}>
        <Container>
          {searchResult?.categories && (
            <CategoryBar categories={searchResult?.categories} noSidePadding />
          )}
          <ProductSectionContainer>
            <ProductSectionContent>
              {isDesktop && (
                <>
                  <SettingsWrapper>
                    {selectedSorter && (
                      <ProductSorter
                        selectedSorter={selectedSorter}
                        sorters={sorters}
                        onSorterClick={onSorterChange}
                      />
                    )}
                    <GridListToggle
                      onClick={setListView}
                      isListView={listView}
                    />
                  </SettingsWrapper>
                </>
              )}
              <FilterProductGrid>
                {((isDesktop && showFilter) || !isDesktop) && (
                  <FilterContainer>
                    <FilterBarExt
                      sorters={sorters}
                      onSorterClick={onSorterChange}
                      onFilterChange={onFilterChange}
                      filters={searchResult?.filters}
                      priceValue={priceValue}
                      facetFilters={facetFilters}
                      setFacetFilters={setFacetFilters}
                      currency={searchResult?.productSearchResult?.currency}
                      filterValue={filter}
                      clearFilter={clearFilter}
                    />
                  </FilterContainer>
                )}
                <ProductsContainer>
                  <StyledGrid
                    width={GridWidth.ScreenWidth}
                    listViewGrid={listView}
                  >
                    {searchResult && !searchResult.productSearchResult && (
                      <NoResultText>{noFilterResultLabel}</NoResultText>
                    )}
                    {searchResult &&
                      !!!searchResult.productSearchResult?.length &&
                      searchResult.productSearchResult?.items?.map(
                        (product: ProductCardModel) => (
                          <ProductCardExt
                            isListView={listView}
                            key={product.code}
                            item={product}
                            showFilter={showFilter}
                          />
                        )
                      )}
                  </StyledGrid>
                  {searchResult?.productSearchResult?.items?.length <
                    searchResult?.productSearchResult?.availableItems && (
                    <LoadMoreContainer
                      loaded={loaded}
                      counterText={counterText}
                      loadMore={loadMoreText}
                      disabled={disabled}
                      onClick={() => fetchMoreItems()}
                    />
                  )}
                </ProductsContainer>
              </FilterProductGrid>
            </ProductSectionContent>
          </ProductSectionContainer>
        </Container>
      </ProductContainer>
    </GridItem>
  );
}

const FilterBarExt = styled(FilterBar, {
  '@mediaMinHuge': {
    maxWidth: '280px',
    minWidth: '280px',
  },
});

const ProductSectionContainer = styled('div', {
  background: '$white',
  mx: -4,
  '@mediaMinMedium': {
    mx: -5,
  },
  '@mediaMinHuge': {
    mx: 'calc((1200px - 100vw) / 2)',
  },
});

const ProductSectionContent = styled('div', {
  '@mediaMinHuge': {
    maxW: '1200px',
    mx: 'auto',
  },
});

const ProductCardExt = styled(ProductCard, {
  variants: {
    showFilter: {
      true: {
        '@mediaMinHuge': {
          gridColumnEnd: 'span 4',
        },
      },
    },
  },
});

const Container = styled('div', {
  px: 0,
  mb: 0,
  w: '100%',
});

const NoResultText = styled(`div`, {
  gridColumnEnd: 'span 12',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '50vh',
});

const FilterContainer = styled(`div`, {
  width: 'auto',
});

const SettingsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '34px',
  px: 5,
  mx: 'auto',
  pb: 8,
  backgroundColor: '$white',
});

const FilterProductGrid = styled('div', {
  display: 'flex',
  g: '20px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  px: 4,
  mx: 'auto',
  backgroundColor: '$white',
  position: 'relative',
  '@mediaMinMedium': {
    px: 5,
  },
  '@mediaMinHuge': {
    g: '40px',
    flexDirection: 'row',
    px: 0,
  },
  '@mediaMaxVerySmall': {
    pl: '24px',
    pr: '24px',
  },
});

const StyledGrid = styled(Grid, {
  g: 8,
  overflow: 'visible',
  margin: 0,
});

const ProductContainer = styled(`div`, {
  mx: 'auto',
  my: 0,
  mt: 6,
  px: 0,
  display: 'flex',
  '@mediaMinHuge': {
    width: '100%',
    maxWidth: `$screenMaxWidth`,
    mt: 12,
  },
});

const ProductsContainer = styled(`div`, {
  display: 'flex',
  flexDirection: 'column',
  g: '60px',
  mb: '40px',
  w: '100%',
});

export default ProductListingBlock;
